<template>
  <section
    data-key-anchor="Документация"
    :class="$customSectionClasses(props.cfgs)"
  >
    <div class="container">
      <div class="document-block__title">
        <div class="h2">{{ values.title }}</div>
        <div v-html="values.description">
        </div>
      </div>
      <div class="doc-root">
        <div class="scroll-customize tabs">
          <div
            v-for="tab in tabsList"
            :key="`tab-docs-${tab.title}`"
            :class="{'active':currentTab?.title === tab.title }"
            class="tab"
            @click="onChangeCurrentTab(tab)"
          >
            {{ tab.title }}
          </div>
        </div>
        <div class="content">
          <div
            v-for="doc in currentTab?.files"
            :key="`doc-content-${doc.name}`"
            class="document"
          >
            <div class="document-image">
              <img
                src="~/assets/img/svg/documents/doc.svg"
                alt="doc"
              >
            </div>
            <div class="document-text">
              <div class="document-title">{{ doc.name }}</div>
              <div class="document-date">{{ doc.caption }}</div>
            </div>

            <a
              class="link-absolute"
              target="_blank"
              :href="doc?.path"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import type { IBlockDefaultProps } from '#sitis/internal/models/common';
import type { SectionTypes } from '~/plugins/customSectionClasses';

type TabType = {
	title: string;
};

type DocumentationFileType = {
	caption: string;
	document: number;
	name: string;
	path?: string;
}

type DocumentationListType = {
	title: string;
	files: DocumentationFileType[];
}

type DocumentationBlockType = Omit<IBlockDefaultProps, 'values' | 'cfgs' | 'extras'> & {
	values: {
		title: string;
		description: string;
		list: DocumentationListType[];
	};
	cfgs: SectionTypes;
	extras: {
		documents: DocumentationListType[];
	}
}

const { $customSectionClasses } = useNuxtApp();
const props = defineProps<DocumentationBlockType>();

const tabsList = ref<TabType[]>([]);
const currentTab = ref<DocumentationListType | null>();

const initTabs = () => {
  props.extras.documents.forEach((t) => {
    tabsList.value.push({ title: t.title });
  });

  currentTab.value = props.extras.documents?.[0];
};

const onChangeCurrentTab = (tab: TabType) => {
  currentTab.value = props.extras.documents.find((t) => t.title === tab.title);
};

onMounted(() => {
  initTabs();
});
</script>

<style scoped lang="scss">
@import "@/assets/scss/media";

.document-block__title {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	margin-bottom: 34px;

	&:deep(p) {
		font-size: 18px;
		color: var(--primary-60);
		font-weight: 600;
	}

	&:deep(a) {
		color: var(--secondary);
	}

	& > .h1 {
		margin-bottom: 0;
	}
}

.doc-root {
	border-radius: 23px;
	background: var(--primary-10);
	padding: 32px;
	position: relative;
	overflow: hidden;

	& .tabs {
		display: flex;
		gap: 28px;
		border-bottom: 1px solid var(--primary-15);
		overflow-x: auto;
	}

	& .tab {
		padding-bottom: 26px;
		cursor: pointer;
		color: var(--primary-60);
		position: relative;
		white-space: nowrap;

		&::before {
			content: none;
			position: absolute;
			height: 4px;
			width: 100%;
			bottom: 0;
			left: 0;
			background: var(--secondary);
		}

		&.active {
			color: var(--primary);

			&::before {
				content: '';
			}
		}
	}

	.content {
		margin-top: 4px;
		display: flex;
		flex-wrap: wrap;
		margin-left: -25px;
		position: relative;

		& > div {
			width: calc(100% / 3 - 25px);
			margin-top: 20px;
			margin-left: 25px;
		}
	}

	.document {
		display: flex;
		padding-bottom: 20px;
		position: relative;
		gap: 10px;

		&::before {
			content: '';
			position: absolute;
			height: 1px;
			width: 100%;
			bottom: 0;
			left: 0;
			background: var(--primary-15);
		}

		&-image {
			min-width: 30px;
			width: 30px;
		}

		&-text {
			display: flex;
			flex-direction: column;
		}

		&-title {
			font-weight: 600;
			color: var(--primary);
			font-size: 15px;
			margin-bottom: 12px;
		}

		&-date {
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 110%;
			color: var(--primary-40);
		}
	}

	&::after {
		content: "";
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		height: 34px;
		background: var(--primary-10);
		pointer-events: none;
	}
}

@include media('md') {
	.document-block__title {
		flex-direction: column;
		align-items: flex-start;
		gap: 10px;
	}
}

@include media('md') {
	.document-block__title {
		margin-bottom: 20px;

		&:deep(p) {
			font-size: 12px;
			font-style: normal;
			line-height: 130%;
			color: var(--primary-60);
			font-weight: 600;
		}
	}

	.doc-root {
		padding: 20px;

		&::after {
			content: none;
		}

		& .content {
			margin-left: 0;
			margin-top: 10px;

			& > div:last-child {
				padding-bottom: 0;

				&::before {
					content: none;
				}
			}

			& > div {
				width: 100%;
				margin-top: 14px;
				margin-left: 0;
			}
		}

		& .tab {
			padding-bottom: 14px;
		}

		& .document {
			&-title {
				margin-bottom: 6px;
			}
		}
	}
}
</style>
